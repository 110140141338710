/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent"
import Helmet from 'react-helmet'
import Header from "./header"
import "./layout.css"

import styled from "styled-components";

import { Row, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.css';

import('./layout.scss')

export const Banner = styled(CookieConsent)`
align-items: baseline;
background: rgb(0,0,0);
color: white;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
left: 0px;
position: fixed;
width: 100%;
z-index: 9999;
`;


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)
  return (
    <>
     <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description }
          ]}
        >
          <html lang="en" />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="container-fluid flex-grow">
        {children}
      </div> 
        <footer className="container-fluid">
        <Row className="mt-4 mb-2 justify-content-between">
          <Col className="col-sm-auto col-12">
          Carol Burri, Davidsrain 15, 4056 Basel, <a href="mailto:mail@carolburri.com">mail@carolburri.com</a> 
          </Col>
          <Col className="col-md-4 col-lg-2 col-12 d-sm-block d-none">
              <span className="light">
              © {new Date().getFullYear()}, Built with
              {` `}
              <a href="https://www.gatsbyjs.org">Gatsby</a>
            </span>
          </Col>
        </Row>
        </footer>
        <Banner
        location="bottom"
        buttonText="Accept"
        style={{ background: "white", color: "black", borderTop: "1px solid black"}}
        buttonStyle={{ background: "black", color: "white" }}
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics">
        This website stores cookies on your computer. These cookies are used to collect information about how you interact with this website and allow us to remember you.
        We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors on this website.
    </Banner>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
