import { StaticQuery, graphql } from "gatsby"
import React from "react"

import Darkmode from '../components/darkmode'

import 'bootstrap/dist/css/bootstrap.css';

import('../components/header.scss')



const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressSiteMetadata {
          name
        }
      }
    `}
  render={data => (
  <header className="flex-column pb-4">
    <Darkmode></Darkmode>
  </header>
  )}
  />
)


export default Header
