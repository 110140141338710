import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { FaMoon, FaSun } from 'react-icons/fa';

class SwitchTheme extends React.Component {
    render () {
        return (
            <ThemeToggler>
                {({ theme, toggleTheme}) => (
                    <div className="darkmode">
                    <label htmlFor="toggle">
                        <input 
                            type="checkbox"
                            id="toggle"
                            className="toggle-input"
                            onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                            checked={theme === 'dark'}
                        />
                     </label>
                     <div className="controle-me">
                     <FaMoon className="moon" /><FaSun className="sun" />
                     </div>
                    </div>
                )} 
            </ThemeToggler>
        )
    }
}

const Darkmode = () => {
    return (
        <SwitchTheme></SwitchTheme>
    )
}

export default Darkmode